import './styles.scss';

const AppTitle = ({ text }) => {


    return (
        <h1 className="app-title">
            {text}
        </h1>
    )
}

export default AppTitle;